import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsPage from '@/views/TabsPage.vue'

import { getAuth } from "firebase/auth";

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: () => import('@/views/WebViewPage.vue'),
    meta: {
      title: 'HomePage'
    }
  },
  {
    path: '/login/aff',
    name: 'login',
    component: () => import('@/views/LoginPage.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/LoginPage.vue'),
    meta: {
      title: 'Login'
    }
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/RegisterPage.vue'),
    meta: {
      title: 'Register'
    }
  },
  {
    path: '/register/:refId',
    component: () => import('@/views/RegisterWebviewPage.vue'),
    meta: {
      title: 'Register'
    }
  },
  {
    path: '/verify-email',
    name: 'verifyEmail',
    component: () => import('@/views/EmailVerification.vue'),
    meta: {
      title: 'Verify Email Address',
      verifyPage: true
    }
  },
  {
    path: '/welcome-tab',
    name: 'welcomeTab',
    component: () => import('@/views/AfterRegistrationPage.vue'),
    meta: {
      title: 'Welcome to Avalanche-wealth',
    }
  },
  {
    path: '/membership/:type',
    component: () => import('@/views/MembershipCoursePage.vue'),
    meta: {
      title: 'Membership view',
      requiresAuth: true
    }
  },
  {
    path: '/newsfeed',
    component: () => import('@/views/NewsFeedPage.vue'),
    meta: {
      title: 'Newsfeed',
      requiresAuth: true
    }
  },
  {
    path: '/Bluepips-sales-page',
    component: () => import('@/views/BluepipsSalesPage.vue'),
    meta: {
      title: 'Bluepips-sales-page',
      requiresAuth: true
    }
  },
  {
    path: '/investment-portal',
    component: () => import('@/views/InvestmentPage.vue'),
    meta: {
      title: 'Investment Portal',
      requiresAuth: true
    }
  },
  {
    path: '/pay',
    component: () => import('@/views/PaynowPage.vue'),
    meta: {
      title: 'Welcome Video',
    }
  },
  {
    path: '/messages',
    component: () => import('@/views/MessagesPage.vue'),
    meta: {
      title: 'Messages',
      requiresAuth: true
    }
  },
  {
    path: '/notifications',
    component: () => import('@/views/NotificationsPage.vue'),
    meta: {
      title: 'notifications',
      requiresAuth: true
    }
  },
  {
    path: '/course/:title',
    component: () => import('@/views/CourseListPage.vue'),
    meta: {
      title: 'Course List',
      requiresAuth: true
    }
  },
  {
    path: '/course-view/:title/:number/:id',
    component: () => import('@/views/CourseViewPage.vue'),
    meta: {
      title: 'Course List',
      requiresAuth: true
    }
  },
  {
    path: '/test',
    component: () => import('@/views/TestPage.vue'),
    meta: {
      title: 'Test'
    }
  },
  {
    path: '/terms-and-conditions',
    component: () => import('@/views/TermsPage.vue'),
    meta: {
      title: 'Terms and condditions',
      requiresAuth: true
    }
  },
  {
    path: '/past-trades',
    component: () => import('@/views/PastTradesPage.vue'),
    meta: {
      title: 'Past',
      requiresAuth: true
    }
  },
  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: '',
        redirect: '/tabs/tab1'
      },
      {
        path: 'tab1',
        component: () => import('@/views/Tab1Page.vue'),
        meta: {
          title: 'Home',
          requiresAuth: true
        }
      },
      {
        path: 'tab2',
        component: () => import('@/views/Tab2Page.vue'),
        meta: {
          title: 'Signal Room',
          requiresAuth: true
        }
      },
      {
        path: 'tab3',
        component: () => import('@/views/InvestmentPage.vue'),
        meta: {
          title: 'Investment Portal',
          requiresAuth: true
        }
      },
      {
        path: 'tab4',
        component: () => import('@/views/Tab4Page.vue'),
        meta: {
          title: 'Dashboard',
          requiresAuth: true
        }
      }
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | Avalanche-wealth`;

  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const verifyPage = to.matched.some(record => record.meta.verifyPage);

  const auth = getAuth();
  const isAuthenticated = auth.currentUser

  let isVerified = false
  if(isAuthenticated) {
    isVerified = auth.currentUser.emailVerified
  }

  // check if user is logged in auth.currrentUser and add it to to requiresAuth
  if(isAuthenticated != null && !isVerified && !verifyPage) {
    next('/verify-email')
  }
  else if(requiresAuth && !isAuthenticated) {
    next('/login')
  } else {
    next();
  }
  
})

export default router
