<template>
  <div id ="contact-btn" @click="openSite()">
    <ion-icon slot="start" :icon="logoWhatsapp" style="margin-right: 5px;"></ion-icon>
    Contact Us
  </div>
</template>

<script>
import { IonIcon } from "@ionic/vue";
import { logoWhatsapp } from "ionicons/icons";
import { Browser } from '@capacitor/browser';

export default {
  components: { IonIcon },
  methods: {
    async openSite() {
      await Browser.open({ url: 'https://wa.me/+2348136096954' });
    }
  },
  setup() {
    return { logoWhatsapp };
  },
}
</script>

<style scoped>
#contact-btn {
  display: flex;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 130px;
  height: 6%;
  background: rgba(30, 218, 105, 0.749);
  color: white;
  border: 3px solid rgb(4, 75, 19);
  position: fixed;
  top: 90%;
  right: 10px;
  box-shadow: inset 2px -2px 9px #1ca153, inset -2px 2px 9px #d2d2d2;
}
</style>