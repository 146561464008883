<template>
  <ion-app>
    <ion-router-outlet />
  </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet, useBackButton, useIonRouter } from '@ionic/vue';
import { defineComponent } from 'vue';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Plugins } from '@capacitor/core';
import { PushNotifications } from "@capacitor/push-notifications";
const { App } = Plugins;
import { FCM } from '@capacitor-community/fcm';
const fcm = FCM
const url = window.location.href
const urlSearchParams = new URLSearchParams(url.split("?")[1])
const refId = urlSearchParams.get("ref")


export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  mounted() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermission().then((result) => {
      alert("result " + JSON.stringify(result));
    });
     // Add registration error if there are.
    PushNotifications.addListener("registrationError", (error) => {
      console.log(`error on register ${JSON.stringify(error)}`);
    }),
    // Add Notification received
    PushNotifications.addListener(
      "pushNotificationReceived",
      (notification) => {
        console.log(`notification ${JSON.stringify(notification)}`);
      }
    ),
    // Add Action performed
    PushNotifications.addListener(
      "pushNotificationActionPerformed",
      async (notification) => {
          alert("notification " + notification)
        console.log("notification succeeded");
      }
    ),
    // Initialize the registration with FCM Token
    PushNotifications.register();
    const fcmToken = fcm.getToken();
    // alert(JSON.stringify(fcmToken));
    console.log("token:" + JSON.stringify(fcmToken));
  },
  created() {

    if(refId) {
      localStorage.setItem("Avc-Wealth-Ref", refId)
    }

    const setStatusBarStyleLight = async () => {
      await StatusBar.setStyle({ style: Style.Light });
    };

    setStatusBarStyleLight()
  },
  setup() {
    const ionRouter = useIonRouter();
    useBackButton( -1, () => {
        if (!ionRouter.canGoBack()) {
          if(confirm('are you sure want to exit app.') == true) {
            App.exitApp();
          }
        }
    })
  }
});
</script>
