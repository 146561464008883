<template>
  <ion-page>
    <ion-content :fullscreen="true">
      <slot />
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent
} from "@ionic/vue";

export default {
  props: [  ],
  components: {
    IonPage,
    IonContent
  },
};
</script>