import {createStore} from 'vuex';
import { db } from '../firebase';
import { doc, getDoc } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";
import { toastController } from "@ionic/vue";

const store = createStore({
  state: {
    currentUser: null,
    newsFeed: [],
    publicPath: process.env.BASE_URL,
    membershipCardInfo:
    [
      {
        'img':'assets/img/membership-placeholder/bluepips-academy.jpg',
        'title': 'Bluepips Trading Academy',
        'fee': '15000'
      },
      {
        'img':'assets/img/membership-placeholder/forex & synthetic.jpg',
        'title': 'Forex & Synthetic Beginners Trading Courses',
        'fee': '15000'
      },
      {
        'img':'assets/img/membership-placeholder/digital-wealth.jpg',
        'title': 'Digital Wealth Marketing Courses',
        'fee': '1500'
      },
      {
        'img':'assets/img/membership-placeholder/signal room.jpg',
        'title': 'Bluepips Trading Signal Room',
        'fee': '1500'
      },
      {
        'img':'assets/img/membership-placeholder/digital-wealth-aff.jpg',
        'title': 'Digital Wealth Affiliate Marketing Courses',
        'fee': '1500'
      },
      {
        'img':'assets/img/membership-placeholder/mobile phone monetization course.jpg',
        'title': 'Mobile Phone Monetization Course',
        'fee': '15000'
      },
      {
        'img':'assets/img/membership-placeholder/mini importation and drop shipping course.jpg',
        'title': 'Mini Importation And Drop Shipping Course',
        'fee': '15000'
      },
      {
        'img':'assets/img/membership-placeholder/web development.jpg',
        'title': 'Web Development',
        'fee': '15000'
      },
      {
        'img':'assets/img/membership-placeholder/mobile app development.jpg',
        'title': 'App Development',
        'fee': '15000'
      },
      {
        'img':'assets/img/membership-placeholder/welath-creation-inner-circle.jpg',
        'title': 'Wealth Creation Inner Circle',
        'fee': '15000'
      },
      {
        'img':'assets/img/membership-placeholder/bluepipspro-live-trading.jpg',
        'title': 'Bluepipspro Live Trading Sessions',
        'fee': '15000'
      },
      {
        'img':'assets/img/membership-placeholder/spiritual-teaching.jpg',
        'title': 'Believers Growth Materials',
        'fee': 'free'
      },
      {
        'img':'assets/img/membership-placeholder/member-workshop.jpg',
        'title': 'Bluepips Trading Workshop',
        'fee': 'free'
      },
      {
        'img':'assets/img/membership-placeholder/member-workshop.jpg',
        'title': 'Exponential Business Growth Summit',
        'fee': 'free'
      },
      {
        'img':'assets/img/membership-placeholder/member-workshop.jpg',
        'title': 'Amazon KDP + Smart Money Class',
        'fee': 'free'
      }
    ],
    coursesDetails:
      [
        {
          'img':'assets/img/course/cp1.jpg',
          'title': 'Digital Marketing',
          'description': ''
        },
        {
          'img':'assets/img/course/cp2.jpg',
          'title': 'Forex Trading Course',
          'description': ''
        },
        {
          'img':'assets/img/course/cp3.jpg',
          'title': 'Crypto Trading Course',
          'description': ''
        },
        {
          'img':'assets/img/course/cp4.jpg',
          'title': 'Synthetic Indices Course',
          'description': ''
        },
        {
          'img':'assets/img/course/cp5.jpg',
          'title': 'Amazon Kdp course',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Ecommerce-sites-Course.jpg',
          'title': 'How to create E-commerce sites',
          'description': ''
        },
        {
          'img':'assets/img/course/facebook-youtube-Course.jpg',
          'title': 'Facebook Youtube Ads',
          'description': ''
        },
        {
          'img':'assets/img/course/social-media-marketing-Course.jpg',
          'title': 'Social Media Marketing',
          'description': ''
        },
        {
          'img':'assets/img/course/Forex-Beginners-Trading-Course-min.png',
          'title': 'Forex & Synthetic Beginners Trading Course',
          'description': ''
        },
        {
          'img':'assets/img/course/Google-marketing-Course-min.png',
          'title': 'Google Marketing Course',
          'description': ''
        },
        {
          'img':'assets/img/course/sms-marketing-Course-min.png',
          'title': 'SMS Marketing Course',
          'description': ''
        },
        {
          'img':'assets/img/course/email-marketing-Course-min.png',
          'title': 'Email Marketing Course',
          'description': ''
        },
        {
          'img':'assets/img/course/content-marketing.png',
          'title': 'Content Marketing Course',
          'description': ''
        },
        {
          'img':'assets/img/course/information-marketing-min.png',
          'title': 'Information Marketing Course',
          'description': ''
        },
        {
          'img':'assets/img/course/mini-importation-min.png',
          'title': 'Importation And Shipping Course',
          'description': ''
        },
        {
          'img':'assets/img/course/whatsapp-marketing-Course-min.png',
          'title': 'Whatsapp Marketing Course',
          'description': ''
        },
        {
          'img':'assets/img/course/graphics-min.png',
          'title': 'Graphics Design Course 1',
          'description': ''
        },
        {
          'img':'assets/img/course/graphics-min.png',
          'title': 'Graphics Design Course 2',
          'description': ''
        },
        {
          'img':'assets/img/course/video-editing-min.png',
          'title': 'Video Editing',
          'description': ''
        },
        {
          'img':'assets/img/course/whiteboard-animation-min.png',
          'title': 'Whiteboard Animation',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Apostle Fortunate- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'GodAddict- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Bishop David Oyedepo- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Apostle Lawal Suleiman- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Dr Val Oje- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Pastor Praise Egbonrelu- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Apostle Babs Adewunmi- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Apostle Joshua Selman- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Apostle Arome Osayi- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Apostle Michael Oropko- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Rev Tolu Agboola- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Apostle Femi Lazarus- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Apostle Edu- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/Spiritual-Teaching-Class.jpg',
          'title': 'Apostle Irene- Believers Growth Materials',
          'description': ''
        },
        {
          'img':'assets/img/course/course-workshop.jpg',
          'title': 'Bluepips Trading Workshop course',
          'description': ''
        },
        {
          'img':'assets/img/course/course-workshop.jpg',
          'title': 'Exponential Business Growth Summit course',
          'description': ''
        },
        {
          'img':'assets/img/course/course-workshop.jpg',
          'title': 'Amazon KDP + Smart Money Class',
          'description': ''
        },
        {
          'img':'assets/img/course/graphic-design-3-min.png',
          'title': 'Smartphone Graphics Designer 3',
          'description': ''
        },
        {
          'img':'assets/img/course/money-making-min.png',
          'title': 'How To Make Consistent $2000 Monthly',
          'description': ''
        }
      ],
    videosUrl: ""
  },
  getters: {
    currentUser(state) {
      return state.currentUser;
    },
    currentNewsFeed(state) {
      return state.newsFeed;
    },
    videosUrl(state) {
      return state.videosUrl;
    },
    publicPath(state) {
      return state.publicPath;
    },
    coursesDetails(state) {
      return state.coursesDetails
    },
    membershipCardInfo(state) {
      return state.membershipCardInfo
    }
  },
  actions: {
    async fetchCurentUserDetails({commit}) {
      // function to count the number of unread messages and notifications
      const countRead = (_array) => {
        let i = 0;
        _array.forEach(element => {
          if(element.isRead === false) {
            ++i
          }
        });
        return i;
      }

      const auth = getAuth();
      const uid = auth.currentUser.uid;

      const docRef = doc(db, "users", uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        let type = null;
        if(countRead(docSnap.data().messages) > 0 ) {
          type = "messages";
          const toast = await toastController.create({
            message: `You have new ${type}`,
            duration: 99999999,
            buttons: [
              {
                text: 'view',
                role: 'info',
                handler: () => { this.$router.push(`/${type}`) }
              },
              {
                text: 'Dismiss',
                role: 'cancel',
                handler: () => { this.handlerMessage = 'Dismiss clicked';}
              }
            ]
          });
          await toast.present();
        }

        if(countRead(docSnap.data().notifications) > 0 ) {
          type = "notifications";
          const toast = await toastController.create({
            message: `You have new ${type}`,
            duration: 99999999,
            buttons: [
              {
                text: 'view',
                role: 'info',
                handler: () => { this.$router.push(`/${type}`) }
              },
              {
                text: 'Dismiss',
                role: 'cancel',
                handler: () => { this.handlerMessage = 'Dismiss clicked';}
              }
            ]
          });
          await toast.present();
        }

        commit('setCurrentUser', docSnap.data())
        return true
      } else {
        console.log("No user document!");
        return false
      }
    },
    fetchCurentNewsFeed({commit}, data) {
      commit('setCurrentNewsFeed', data)
    },
    fetchVideosUrl({commit}, data) {
      commit('setVideosUrl', data)
    },
    async logout({commit}) {
      if(confirm('are you sure you want to logout.') == true) {
        const auth = getAuth();
        await signOut(auth).then(() => {
        commit('setCurrentUser', null)
          location.reload();
          return true
        }).catch((error) => {
          console.log(error)
          return false
        });

      }
    },
    checkLogin() {
      const auth = getAuth();
      const isAuthenticated = auth.currentUser
      if(isAuthenticated) {
        return true
      } else {
        return false
      }
    }
  },
  mutations: {
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setVideosUrl(state, payload) {
      state.videosUrl = payload;
    },
    setCurrentNewsFeed(state, payload) {
      state.newsFeed = payload;
    }
  }
});

export default store